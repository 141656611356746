<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <vs-button class="mt-2 mr-2" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="handleCreate()">Create</vs-button>
            <!-- <vs-button class="mt-2 mr-2" color="primary" type="border" icon-pack="feather" icon="icon-upload" @click="handleImport()">Import</vs-button> -->
        </div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Source Warehouse</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Warehouse @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></Warehouse>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Transaction Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Date :default="false" @selected="(val) => {
                        this.transactionDate = val
                    }"></Date>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table :draw="draw" :status="activeTab" :transaction-date="transactionDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import Table from "./component/Table.vue";
export default {
    components: {
        Warehouse,
        Date,
        Table
    },
    data() {
        return {
            activeTab: "New",
            draw: 0,
            transactionDate: null,
            selectedSrcWarehouse: null
        }
    },
    methods: {
        handleCreate() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName + "-create",
            });
        },
        handleImport() {
            this.$router.push({
                name: "outbound.simple-sto-import"
            })
        },
/*
        handleExport() {
            var fileTitle = "SIMPLE_STO_EXPORT";
            fileTitle += "_" + moment().format("YYYYMMDDHHmmss");

            this.$http
                .get(this.$store.state.outbound.simpleSto.baseUrlPath + "/export", {
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/octet-stream",
                    },
                })
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.status == "error") {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        var fileURL = window.URL.createObjectURL(new Blob([resp]));
                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;

                        fileLink.setAttribute(
                            "download",
                            fileTitle.toUpperCase() + ".xlsx"
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }
                });
        },
*/
        handleFilter() {
            this.draw++;
        },
    }
}
</script>