<template>
    <vx-card title="Inter Warehouse">
        <div class="vx-input-group flex">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="New">
                    <tab-new></tab-new>
                </vs-tab>
                <vs-tab label="Waiting">
                    <tab-waiting-approval></tab-waiting-approval>
                </vs-tab>
                <!-- <vs-tab label="Inquiry">
                    <tab-inquiry></tab-inquiry>
                </vs-tab> -->
                <vs-tab label="Pending">
                    <tab-pending></tab-pending>
                </vs-tab>
                <!-- <vs-tab label="Approved">
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Picking">
                    <tab-picking></tab-picking>
                </vs-tab> -->
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Executed">
                    <tab-executed></tab-executed>
                </vs-tab>
                <vs-tab label="Completed">
                    <tab-complete></tab-complete>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
                <vs-tab label="Canceled">
                    <tab-canceled></tab-canceled>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabNew from "./TabNew.vue";
import TabWaitingApproval from "./TabWaitingApproval.vue";
import TabInquiry from "./TabInquiry.vue";
import TabPending from "./TabPending.vue";
import TabApproved from "./TabApproved.vue";
import TabPicking from "./TabPicking.vue";
import TabHandover from "./TabHandover.vue";
import TabExecuted from "./TabExecuted.vue";
import TabComplete from "./TabComplete.vue";
import TabRejected from "./TabRejected.vue";
import TabCanceled from "./TabCanceled.vue";
export default {
    components: {
        TabNew,
        TabWaitingApproval,
        TabInquiry,
        TabPending,
        TabApproved,
        TabPicking,
        TabHandover,
        TabExecuted,
        TabComplete,
        TabRejected,
        TabCanceled,
    },
    data() {
        return {
            activeTab: "New",
            tabStatus: "",
            render: true,
        }
    },
    methods: {},
    mounted() {}
}
</script>